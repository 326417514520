var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ls-page"},[_vm._m(0),_c('div',{staticClass:"ps-section__content ls-page-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"},[_vm._m(1),_c('div',{staticClass:"modal fade",attrs:{"id":"ShopNowModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_vm._m(2),_c('div',{staticClass:"modal-body"},[_vm._v(" Carrying a wide range of merchants and their products, our market aims to have all the products you need ready at your finger tips. "),_c('p',[_c('router-link',{attrs:{"to":{ name: 'shop_products' }}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v(" Visit the Market place ")])])],1)]),_vm._m(3)])])])]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"},[_vm._m(6),_c('div',{staticClass:"modal fade",attrs:{"id":"BecomeAMerchantModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_vm._m(7),_c('div',{staticClass:"modal-body ls-modal-body"},[_c('h4',[_vm._v(" Want to become a Pahukama e-marketplace merchant? ")]),_c('p',[_vm._v(" Do you have products that you want sold on-line to Diaspora global customers for their loved ones and friends or consumers in Zimbabwe? Fill in our application form and we will get back to you. ")]),_c('router-link',{attrs:{"to":{ name: 'merchant_register' }}},[_c('button',{staticClass:"btn btn-primary"},[_vm._v(" Register Here ")])])],1),_vm._m(8)])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ps-section__header ls-page-header"},[_c('h1',{staticClass:"container ls-page-header-title"},[_vm._v("How it Works")]),_c('h3',{staticClass:"container ls-page-header-subtitle"},[_vm._v(" Your Guide to everything @Pahukama "),_c('br'),_vm._v(" Scroll down for our services ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-style-img"},[_c('img',{staticClass:"card-img-bottom",attrs:{"src":"/img/login_new (2).jpg","alt":"Card image cap"}})]),_c('div',{staticClass:"col-9 col-style-body"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("ShopNowPH")]),_c('div',{staticClass:"card-text"},[_vm._v(" The One Stop Shop with all our products from merchants ")])]),_c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#ShopNowModal"}},[_vm._v(" Learn more ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v("ShopNowPH")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Close ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-style-img"},[_c('img',{staticClass:"card-img-bottom",attrs:{"src":"/img/login_new (2).jpg","alt":"Card image cap"}})]),_c('div',{staticClass:"col-9 col-style-body"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("BuyVoucherPH")]),_c('div',{staticClass:"card-text"},[_vm._v(" Your can buy coupons for your loved ones in Zimbabwe that they can redeem at one of our selected stores ")])]),_c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#BuyVoucherModal"}},[_vm._v(" Learn more ")])])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"BuyVoucherModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" BuyVoucherPH ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_vm._v(" ... ")]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Close ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"},[_c('div',{staticClass:"card",attrs:{"id":"pickup-delivery"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-style-img"},[_c('img',{staticClass:"card-img-bottom",attrs:{"src":"/img/login_new (2).jpg","alt":"Card image cap"}})]),_c('div',{staticClass:"col-9 col-style-body"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Pickup & Delivery")]),_c('div',{staticClass:"card-text"},[_vm._v(" Your can buy data for your preferred service provider ")])]),_c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#PickupDeliveryModal"}},[_vm._v(" Learn more ")])])])])]),_c('div',{staticClass:"modal fade",attrs:{"id":"PickupDeliveryModal","tabindex":"-1","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-centered"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Pickup and Delivery ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body ls-modal-body"},[_c('h4',[_c('span',{staticClass:"text-info"},[_vm._v("Pahukama - ")]),_vm._v("How it works with curbside pick and delivery. ")]),_c('div',{staticStyle:{"text-align":"left","display":"flex","justify-content":"flex-start","width":"100%"}},[_c('img',{staticClass:"banner-bottom--image",attrs:{"src":"https://pahukama-site-images.s3.us-east-2.amazonaws.com/process.webp","alt":"Pahukama"}})])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Close ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",attrs:{"id":"pickup-delivery"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 col-style-img"},[_c('img',{staticClass:"card-img-bottom",attrs:{"src":"/img/login_new (2).jpg","alt":"Card image cap"}})]),_c('div',{staticClass:"col-9 col-style-body"},[_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v("Become a Merchant")]),_c('div',{staticClass:"card-text"},[_vm._v(" Want to become a Pahukama e-marketplace merchant? ")])]),_c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#BecomeAMerchantModal"}},[_vm._v(" Learn more ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"exampleModalLabel"}},[_vm._v(" Become a Merchant ")]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-success",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" Close ")])])
}]

export { render, staticRenderFns }