<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h1 class="container ls-page-header-title">How it Works</h1>
      <h3 class="container ls-page-header-subtitle">
        Your Guide to everything @Pahukama <br />
        Scroll down for our services
      </h3>
    </div>
    <div class="ps-section__content ls-page-content">
      <div class="row">
        <!-- ShopNow -->
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="card">
            <div class="row">
              <div class="col-3 col-style-img">
                <img
                  class="card-img-bottom"
                  src="/img/login_new (2).jpg"
                  alt="Card image cap"
                />
              </div>
              <div class="col-9 col-style-body">
                <div class="card-body">
                  <h5 class="card-title">ShopNowPH</h5>
                  <div class="card-text">
                    The One Stop Shop with all our products from merchants
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#ShopNowModal"
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="ShopNowModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">ShopNowPH</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Carrying a wide range of merchants and their products, our
                  market aims to have all the products you need ready at your
                  finger tips.
                  <p>
                    <router-link :to="{ name: 'shop_products' }"
                      ><button class="btn btn-primary">
                        Visit the Market place
                      </button>
                    </router-link>
                  </p>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-success"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- BuyVoucherPH -->
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <div class="card">
            <div class="row">
              <div class="col-3 col-style-img">
                <img
                  class="card-img-bottom"
                  src="/img/login_new (2).jpg"
                  alt="Card image cap"
                />
              </div>
              <div class="col-9 col-style-body">
                <div class="card-body">
                  <h5 class="card-title">BuyVoucherPH</h5>
                  <div class="card-text">
                    Your can buy coupons for your loved ones in Zimbabwe that
                    they can redeem at one of our selected stores
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#BuyVoucherModal"
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="BuyVoucherModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    BuyVoucherPH
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  ...
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-success"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- pickup-delivery -->
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <div id="pickup-delivery" class="card">
            <div class="row">
              <div class="col-3 col-style-img">
                <img
                  class="card-img-bottom"
                  src="/img/login_new (2).jpg"
                  alt="Card image cap"
                />
              </div>
              <div class="col-9 col-style-body">
                <div class="card-body">
                  <h5 class="card-title">Pickup & Delivery</h5>
                  <div class="card-text">
                    Your can buy data for your preferred service provider
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#PickupDeliveryModal"
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="PickupDeliveryModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Pickup and Delivery
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body ls-modal-body">
                  <h4>
                    <span class="text-info">Pahukama - </span>How it works with
                    curbside pick and delivery.
                  </h4>

                  <div
                    style="text-align: left; display: flex; justify-content: flex-start; width: 100%;"
                  >
                    <img
                      src="https://pahukama-site-images.s3.us-east-2.amazonaws.com/process.webp"
                      alt="Pahukama"
                      class="banner-bottom--image"
                    />
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-success"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Become a Merchant -->
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
          <div id="pickup-delivery" class="card">
            <div class="row">
              <div class="col-3 col-style-img">
                <img
                  class="card-img-bottom"
                  src="/img/login_new (2).jpg"
                  alt="Card image cap"
                />
              </div>
              <div class="col-9 col-style-body">
                <div class="card-body">
                  <h5 class="card-title">Become a Merchant</h5>
                  <div class="card-text">
                    Want to become a Pahukama e-marketplace merchant?
                  </div>
                </div>
                <div class="card-footer">
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#BecomeAMerchantModal"
                  >
                    Learn more
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal -->
          <div
            class="modal fade"
            id="BecomeAMerchantModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Become a Merchant
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body ls-modal-body">
                  <h4>
                    Want to become a Pahukama e-marketplace merchant?
                  </h4>
                  <p>
                    Do you have products that you want sold on-line to Diaspora
                    global customers for their loved ones and friends or
                    consumers in Zimbabwe? Fill in our application form and we
                    will get back to you.
                  </p>

                  <router-link :to="{ name: 'merchant_register' }"
                    ><button class="btn btn-primary">
                      Register Here
                    </button></router-link
                  >
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-success"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowTo",
};
</script>
